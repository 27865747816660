<template>
  <v-container>
    <form action="" @submit.prevent="submitFilter">
      <v-row no-gutters align="center" justify="space-between">
        <v-col>
          <v-row>
            <v-col class="font-label-class" cols="6" md="3" xs="4">
              <span class="p-0 m-0">{{ $t("message.label-merchant") }}</span>
              <v-select
                class="p-0 mt-1"
                :items="merchantList"
                v-model="filter.merchant"
                item-text="merchant_name"
                item-value="id"
                clearable
                outlined
              ></v-select>
            </v-col>
          <v-col class=" font-label-class" cols="6" md="3" xs="4">
              <span class="p-0 m-0">{{ $t("message.label-shop") }}</span>
              <v-select
                class="p-0  mt-1"
                v-model="filter.shop"
                outlined
                :items="shopList"
                item-text="store_name"
                item-value="id"
                clearable
              ></v-select>
            </v-col>
            <v-col class=" font-label-class" cols="6" md="2" xs="4">
              <span class="p-0 m-0">{{ $t("message.label-category") }}</span>
              <v-autocomplete
                class="p-0 mt-1"
                item-text="category_name"
                item-value="id"
                :items="categoryList"
                v-model="filter.category"
                clearable
                outlined
                persistent-hint
                :menu-props="{ maxWidth: '20%' }"
              >
                <template v-slot:item="{ item }">
                  <span style="font-size:80%;color:rgba(0,0,0,.45)!important;">{{item.prefix}}</span>{{item.category_name}}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col class=" font-label-class" cols="6" md="4" xs="4">
              <span>{{ $t("message.label-price") }}</span>
              <v-row no-gutters class="row-amount mt-1">
                <v-col>
                  <!-- <v-text-field
                    type="number"
                    v-model="filter.priceStart"
                    outlined
                  ></v-text-field> -->
                  <v-text-field
                    type="number"
                    v-model="filter.priceStart"
                    outlined
                    clearable
                  />
                </v-col>
                <v-col class="minus-amount">
                  <v-icon class="icon-tilde-class">mdi-tilde</v-icon>
                </v-col>
                <v-col>
                  <!-- <v-text-field
                    v-model="filter.priceEnd"
                    type="number"
                    outlined
                  ></v-text-field> -->
                  <v-text-field
                    type="number"
                    v-model="filter.priceEnd"
                    outlined
                    clearable
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col class="" cols="6" md="4" xs="4">
              <span class="p-0 m-0 font-label-class">{{ $t("message.label-product") }}</span>
              <v-text-field
                class="text-field-class mt-1"
                v-model="filter.productName"
                type="text"
                clearable
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col class=" font-label-class" cols="6" md="2" xs="4" style="margin-top: -50px">
          <v-btn
            rounded
            type="submit"
            color="primary"
            style="float: right;"
          >
            <v-icon left>
              {{ iconSearch }}
            </v-icon>
            {{ $t("message.btn-search") }}
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </v-container>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
export default {
  name: "FilterItem",
  data: () => ({
    iconSearch: mdiMagnify,
    filter: {
      merchant: "",
      shop: "",
      category: "",
      priceStart: "",
      priceEnd: "",
      // page: "",
      productName: "",
    },
    valueWhenIsEmpty: "",
    options: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0
    },
  }),
  computed: {
    merchantList() {
      return this.$store.state.approve_module.resultAllMerchant;
    },
    categoryList() {
      return this.$store.state.category_module.categoryAll;
    },
    shopList() {
      return this.$store.state.shop_module.shopResult;
    },
  },
  created() {
    this.fetchMerchantList();
    this.fetchCategoryList();
    this.fetchShopList();
    this.initFilterProduct()
  },
  methods: {
    submitFilter() {
      if(!this.filter.productName) this.filter.productName = ""
      if(!this.filter.merchant) this.filter.merchant = ""
      if(!this.filter.shop) this.filter.shop = ""
      if(!this.filter.category) this.filter.category = ""
      if(!this.filter.priceStart) this.filter.priceStart = ""
      if(!this.filter.priceEnd) this.filter.priceEnd = ""
      
      this.$store.dispatch("product_module/fetchProductList", {
        merchant: this.filter.merchant === null ? "" : this.filter.merchant,
        shop: this.filter.shop === null ? "" : this.filter.shop,
        category: this.filter.category === null ? "" : this.filter.category,
        priceStart: this.filter.priceStart === null ? "" : this.filter.priceStart,
        priceEnd: this.filter.priceEnd === null ? "" : this.filter.priceEnd,
        productName: this.filter.productName === null ? "" : this.filter.productName,
        page: 1
      });
    },

    initFilterProduct() {
      this.filter.merchant = this.$store.state.product_module.merchant;
      this.filter.shop = this.$store.state.product_module.shop;
      this.filter.category = this.$store.state.product_module.category;
      this.filter.priceStart = this.$store.state.product_module.priceStart;
      this.filter.priceEnd = this.$store.state.product_module.priceEnd;
      this.filter.productName = this.$store.state.product_module.productName
    },

    fetchShopList() {
      this.$store.dispatch("shop_module/fetchShopList");
    },
    fetchMerchantList() {
      this.$store.dispatch("approve_module/fetchAllMechant");
    },
    fetchCategoryList() {
      this.$store.dispatch("category_module/fetchAllCategory");
    },
  },
};
</script>

<style>
.btn-search {
  border-radius: 20px;
}
.v-input .v-input__control .v-text-field__details {
  /* centering text field filter order */
  /* display: none; */
}
.v-text-field--enclosed .v-input__append-inner, .v-text-field--enclosed .v-input__append-outer {
  margin-top: 0px;
    align-self: center !important;
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections {
    padding: 0px !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.col-filter-input {
  padding-right: 10px !important;
  padding-left: 0px !important;
}
/* .text-field-class .v-input__control input {
  font-size: 12px;
} */
.label-class {
  font-size: 12px;
}
.minus-amount {
  max-width: fit-content;
}
.minus-amount .icon-tilde-class {
  font-size: 15px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 8px;
}
.font-label-class {
  font-size: 14px !important;
}

@media only screen and (max-width: 640px) {
  .btn-search-filter-customer {
    float: none !important;
  }
  .col-mobile-width {
    width: 50% !important;
  }
}
</style>
