<template>
  <v-container>
    <v-toolbar flat >
      <v-toolbar-title color="primary" >
        <h4 color="primary">
          {{$t("message.title-product")}}
        </h4>
      </v-toolbar-title>
    </v-toolbar>

    <FilterProduct />

    <v-pagination
    v-if="!isLength"
    v-model="page"
    @input="changePage"
    :length="lengthPage"
    :total-visible="7"
    depressed
    ></v-pagination>

    <!-- data table -->
    <v-data-table
      :headers="headers"
      :items="productList"
      :hide-default-footer="true"
      item-key="productList.id"
      class="elavation-1"
      style="text-align: center"
      disable-sort
      justify="start"
      :server-items-length="allPage"
      :options.sync="option"
      :items-per-page="itemPerPage"
      :page="currentPage"
      :footer-props="{
        'items-per-page-options': [ itemPerPage ],
      }"
    >
      <!-- imageproduct itemlist loop -->
      <template v-slot:[`item.image`]="{ item }">
        <v-img
          :lazy-src="item.image_url ? item.image_url : notFound"
          :src="item.image_url ? item.image_url : notFound"
          max-height="150"
          max-width="80px"
          style="float: left; margin: 1em 0 1em 0"
        ></v-img>
      </template>

      <template v-slot:[`item.is_active`]="{ item }">
        <div v-if="item.is_active">
          Active
        </div>
        <div v-else>
          Non Active
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="primary" small class="mr-2" @click="toDetail(item.slug)">
          mdi-eye
        </v-icon>
        <v-icon color="red" small @click="deleteItem(item.id)">
          mdi-delete
        </v-icon>
      </template>

        <!-- dialog delete confirmation -->
      <template v-slot:top="">
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >{{$t("message.confirm-delete")}}</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">
                {{$t("message.btn-cancel")}}
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                {{$t("message.btn-ok")}}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import FilterProduct from "@/components/FilterProduct";
import moneyFormat from "../helpers/moneyFormat"

export default {
  name: "ProductManagement",
  components: {
    FilterProduct,
  },
  data: () => ({
    notFound: require('../assets/image-not-found.png'),
    dialog: false,
    dialogDelete: false,
    deletedId: "",
    page: 1,
    option: {},
    message: {}
  }),

  computed: {
    isLength() {
      return this.$store.state.product_module.isLength
    },
    lengthPage() {
      return this.$store.state.product_module.length_page
    },
    allPage() {
      let resp = this.$store.state.product_module.productPagination
      if(resp.total === undefined) {
        return 0
      }
      return resp.total
    },
    currentPage() {
      let resp = this.$store.state.product_module.productPagination
      if(resp.current_page === undefined) {
        return 1
      }
      return resp.current_page
    },  
    itemPerPage() {
      let resp = this.$store.state.product_module.productPagination
      if(resp.per_page === undefined) {
        return 1
      }
      return Number(resp.per_page)
    },
    productList(){
      let productData = this.$store.state.product_module.productsResult

      if (!productData) {
        return productData
      } else {
        productData.map(product => {
          product.price = moneyFormat(product.price)
        })
      }
      return productData
    },
    productPagOpt(){
      return this.$store.state.product_module.productPagination
    },
    headers(){
      return [
      {
        text: this.$t("message.label-id"),
        align: "start",
        value: "id",
        
      },
      { text: this.$t("message.label-merchant"), value: "merchant_name", align: "center"},
      { text: this.$t("message.label-shop"), value: "store_name", align: "center" },
      { text: this.$t("message.label-item"), value: "item_name", align: "center"},
      { text: this.$t("message.label-category"), value: "category_name", align: "center"},
      { text: this.$t("message.label-price"), value: "price",  align: "center"},
      { text: this.$t("message.label-active"), value: "is_active", align: "center" },
      { text: this.$t("message.label-image"), value: "image",  align: "center"},
      { text: this.$t("message.label-action"), value: "actions", align: "center" },
    ]
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    option: {
      handler(){
        this.dispatchProducts()
      }, deep:true
    },
    product_management: {
      handler() {
        this.page = parseInt(this.$store.state.product_module.page)
      },
      deep:true
    }
  },

  created() {
    this.dispatchProducts();
  },
  methods: {
    changePage(event) {
      return this.$store.dispatch("product_module/fetchProductList", {
        page: event
      })
    },
    dispatchProducts() {
      let setPage = parseInt(this.$store.state.product_module.page)
      this.page = parseInt(this.$store.state.product_module.page)
      this.$store.dispatch('product_module/fetchProductList', { page: setPage })

    },

    toDetail(slug){
      // this.$store.dispatch('product_module/fetchProductDetail', slug)
      this.$router.push(`/productDetail/${slug}`)
    },

    editItem(item) {
      this.dialog = true;
    },

    deleteItem(id) {
      this.deletedId = id
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let id = this.deletedId;
      this.message.title = this.$t("message.title-delete-product-success-message")
      this.$store.dispatch("product_module/deleteProductList", {
        id,
        message: {
          title: this.message.title
        }
      });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
    },

    closeDelete() {
      this.dialogDelete = false;
    },
  },
};
</script>

<style>
v-data-table >>> div > table > tr {
  border-spacing: 5px;
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}

thead tr th {
  color: white !important;
  font-size: 15px !important;
  /* text-align: center !important; */
}

tbody tr:hover:not(.v-table__expanded__content) {
  /* background: #c5cae9 !important; */
}
.v-data-footer-top {
  border-top: none !important;
}
.v-data-footer__select {
  margin-left: 0px !important;
}
/* .v-application .text-start {
  text-align: center !important;
} */
.v-application .text-center {
  text-align: start !important;
}
.v-toolbar .v-toolbar__content {
  /* padding-left: 0px !important; */
}
</style>
