var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{attrs:{"color":"primary"}},[_c('h4',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("message.title-product"))+" ")])])],1),_c('FilterProduct'),(!_vm.isLength)?_c('v-pagination',{attrs:{"length":_vm.lengthPage,"total-visible":7,"depressed":""},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e(),_c('v-data-table',{staticClass:"elavation-1",staticStyle:{"text-align":"center"},attrs:{"headers":_vm.headers,"items":_vm.productList,"hide-default-footer":true,"item-key":"productList.id","disable-sort":"","justify":"start","server-items-length":_vm.allPage,"options":_vm.option,"items-per-page":_vm.itemPerPage,"page":_vm.currentPage,"footer-props":{
      'items-per-page-options': [ _vm.itemPerPage ],
    }},on:{"update:options":function($event){_vm.option=$event}},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
    var item = ref.item;
return [_c('v-img',{staticStyle:{"float":"left","margin":"1em 0 1em 0"},attrs:{"lazy-src":item.image_url ? item.image_url : _vm.notFound,"src":item.image_url ? item.image_url : _vm.notFound,"max-height":"150","max-width":"80px"}})]}},{key:"item.is_active",fn:function(ref){
    var item = ref.item;
return [(item.is_active)?_c('div',[_vm._v(" Active ")]):_c('div',[_vm._v(" Non Active ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.toDetail(item.slug)}}},[_vm._v(" mdi-eye ")]),_c('v-icon',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")])]}},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("message.confirm-delete")))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(" "+_vm._s(_vm.$t("message.btn-cancel"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" "+_vm._s(_vm.$t("message.btn-ok"))+" ")]),_c('v-spacer')],1)],1)],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }